<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 17:27:24
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:39:26
 * @FilePath: \dataview-next\src\manage-views\role\sysAuth\components\AuthList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="role-list">
    <div class="main-title">
      <div class="main-label">角色用户</div>
      <div class="search">
        <el-button v-if="judgingAPermission(['role.adduser'])" size="mini" type="primary" icon="el-icon-plus" @click="onAdd">添加用户</el-button>
      </div>
    </div>
    <el-table
      ref="table"
      :data="roleDatas"
      highlight-current-row
      class="list-table"
      :header-cell-style="{ background: '#fafafa' }"
      v-loading="loading"
      node-key="id"
    >
      <el-table-column prop="name" label="名称">
        <template #default="{ row }">
          <div v-if="row.user_info">
            {{row.user_info.name}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号">
        <template #default="{ row }">
          <div  v-if="row.user_info">
            {{row.user_info.phone}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ row }" width="200px">
          <el-popconfirm
            v-if="judgingAPermission(['role.deleteuser'])"
            class="delete-btn"
            title="确定删除这个用户吗？"
            @confirm="onDelete(row)"
          >
            <el-button size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加用户 -->
    <ListAdd
      :cheackId="cheackId"
      :dialog="dialog"
      :roleData="listData"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </ListAdd>
  </div>
</template>

<script>
import { judgingAPermission } from '@/utils/tools'
import { detRoleUser } from '@/api/saasManage'
import ListAdd from './ListAdd.vue'
import { Popconfirm } from 'element-ui'
export default {
  name: 'AuthList',
  components: {
    'el-popconfirm': Popconfirm,
    ListAdd,
  },
  props: {
    roleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      judgingAPermission: judgingAPermission,
      listData: null, // 新增需要获取role的名字和id
      dialog: false,
      roleDatas: null,
      loading: false,
      roleId: '',
      cheackId: [],
    }
  },
  watch: {
    roleData(newVal) {
      this.listData = newVal
      this.loading = true
      if (newVal) {
        this.$nextTick(() => {
          this.cheackId = []
          this.roleId = newVal.id
          this.roleDatas = newVal.role_user
          newVal.role_user.forEach(r=>{
            this.cheackId.push(r.user_info?.id)
          })
        })
      } 
       this.loading = false
    },
  },
  methods: {
    /**
     * @desc:弹窗保存
     */
    dialogSave() {
      this.loading = true
      this.dialog = !this.dialog
      this.$emit('reFresh')
      this.roleDatas = null
      this.loading = false
    },
    dialogClose() {
      this.cheackId = []
      this.dialog = !this.dialog
    },
    /**
     * @desc:普通删除
     */
    onDelete(row) {
      detRoleUser(row.id)
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.roleDatas = null
          this.$emit('reFresh')
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc:添加新用户
     */
    onAdd() {
      if (!this.listData) {
        this.$message.error('请先选择父级角色！')
        return false
      }
      this.dialog = !this.dialog
    },
  },
}
</script>
<style lang="less" scoped>
.main-title {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  height: 53px;
  box-sizing: border-box;
  .main-label {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 700;
  }
}
.delete-btn {
  margin-left: 10px;
  .el-button--text {
    color: #f56c6c;
  }
}
.list-table {
  flex: 1;
  overflow: auto;
}
.search {
  display: flex;
  .search-input {
    width: 150px;
    margin-right: 20px;
  }
}
.role-list {
  display: flex;
  background: #fff;
  margin-left: 8px;
  width: 40%;
  height: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 8px;
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 55px) !important;
    }
  }
}

.el-tab-pane {
  height: 100%;
}
</style>
