<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 13:52:23
 * @LastEditors: zx
 * @LastEditTime: 2022-09-14 15:53:37
 * @FilePath: \dataview-next\src\manage-views\auth\sysAuth\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class='role-container'>
    <RoleManage ref='manage' @row-click='rowClick' ></RoleManage>
    <RoleList ref='list' :roleData='roleData' @reFresh='reFresh'></RoleList>
  </div>
</template>
<script >
import RoleManage from './components/RoleManage'
import RoleList from './components/RoleList'
export default {
  components: { 
    RoleManage,
    RoleList
  },
  data() {
    return {
      roleData:null
    }
  },
  methods: {
    rowClick(row){
      this.roleData = row;
    },
    reFresh(){
      this.$refs.manage.getData()
      this.roleData = null
    },
  }
};
</script>
<style lang='less'>
.role-container{
  background-color:#eff1f4 ;
  width:100%;
  display: flex;
  height:100%;
}
</style>