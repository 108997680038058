<template>
  <div class="list-dialog">
    <el-dialog
      :fullscreen="dialogFull"
      :visible.sync="dialogs"
      width="30%"
      :before-close="onClose"
    >
      <template slot="title">
        <div class="avue-crud__dialog__header">
          <span>
            添加用户
          </span>
          <div
            class="avue-crud__dialog__menu"
            @click="dialogFull ? (dialogFull = false) : (dialogFull = true)"
          >
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>
      <div class="list-add-main">
        <el-tree
          :default-checked-keys="cheackId"
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          show-checkbox
          default-expand-all
          node-key="id"
        >
          <template #default="{ node, data }">
            <div class="archi-label">
              <span class="archi-label-svg">
                <svg aria-hidden="true" class="color-icon">
                  <use :xlink:href="getIcon(data)" />
                </svg>
                <el-tooltip
                  v-if="node && data[defaultProps.label].length > 12"
                  :content="node ? data[defaultProps.label] : ''"
                  effect="dark"
                  :open-delay="500"
                  placement="top"
                >
                  <span>{{ node ? data[defaultProps.label] : '' }}</span>
                </el-tooltip>
                <span v-else>{{ node ? data[defaultProps.label] : '' }}</span>
              </span>
            </div>
          </template>
        </el-tree>
      </div>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">添加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyId} from "@/utils/tools";
import { getDepartmentUser, addRoleOperation } from '@/api/saasManage'
import { Tree } from 'element-ui'
export default {
  name: 'ListAdd',
  components: {
    'el-tree': Tree,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    roleData: {
      type: Object,
      default: () => {},
    },
    cheackId: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roleDatas: null,
      treeData: '',
      dialogs: false,
      dialogFull: false,
      defaultProps: {
        children: 'user_list',
        label: 'name',
      },
    }
  },
  watch: {
    dialog(newVal) {
      this.dialogs = newVal
    },
    roleData(newVal) {
      if (newVal) {
        this.roleDatas = newVal
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 不同组织架构返回不同的icon
    getIcon(data) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
      const section = [7]
      if (!data) {
        return '#iconjituan'
      }
      if (group.indexOf(+data.type) !== -1 ) {
        return '#iconjituan'
      } else if (company.indexOf(+data.type) !== -1 ) {
        return '#icongongsi'
      } else if (project.indexOf(+data.type) !== -1 ) {
        return '#iconxiangmu'
      } else if (section.indexOf(+data.type) !== -1 ) {
        return '#iconbumen'
      }
      return  '#iconrenyuan1'
    },
    getData() {
      getDepartmentUser()
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          let datas = res.data.data
          this.treeData = []
          this.treeData.push(datas)
          //将数组扁平化 变成标准格式
          this.getTreeData(datas)
          this.treeData = this.treeData.flat()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    // 数组扁平化
    getTreeData(data) {
      if (data && data.length > 0) {
        data.forEach((res) => {
          // eslint-disable-next-line no-prototype-builtins
          if (res.hasOwnProperty('children') && res.children.length > 0) {
            this.treeData.push(res.children)
            this.getTreeData(res.children)
          }
        })
      }
    },
    /**
     * @desc: 保存
     */
    onSave() {
      let checkData = this.$refs.tree.getCheckedNodes()
      let createData = []
      checkData.forEach((res) => {
        if (res.id && !res.user_list) {
          createData.push(
            res.id
          )
        }
      })
      if(createData.length > 0){
        const params = {
          id:this.roleDatas.id,
          name:this.roleDatas.name,
          code:this.roleDatas.code,
          sort:this.roleDatas.sort,
          status:this.roleDatas.status,
          saas_user_id:createData,
          company_id:getCompanyId()
        }
        addRoleOperation(params).then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.$refs.tree.setCheckedKeys([]);
          this.$emit('dialogSave')
        })
      }else{
        this.$message.error(`请先选择人员！`)
      }

    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false
      // 不理解当时为什么要清空   先暂时注释 2022/12/30 
      // this.form = {
      //   status: 0, // 是否内置
      //   name: '', //角色名称
      //   remarks: '', // 备注
      //   code: '', //编码
      //   sort: '', //排序
      // }
      // this.$refs.tree.setCheckedKeys([]);
      this.$emit('dialogClose')
    },
  },
}
</script>
<style lang="less" scoped>
.list-dialog {
  text-align: left;
  .list-add-main {
    min-height: 300px;
    .bottom-btn {
      margin: 0;
      position: absolute;
      bottom: 0;
      width: calc(100% - 7px);
      border-top: 1px solid #e8e8e8;
      padding: 3px 0px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      right: 7px;
      background: #fff;
      border-radius: 0 0 4px 4px;
    }
  }
}
  .archi-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    -moz-user-select:none; /* Firefox私有属性 */
    -webkit-user-select:none; /* WebKit内核私有属性 */
    -ms-user-select:none; /* IE私有属性(IE10及以后) */
    -khtml-user-select:none; /* KHTML内核私有属性 */
    -o-user-select:none; /* Opera私有属性 */
    user-select:none; /* CSS3属性 */
    .archi-label-svg {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        flex: 1;
        overflow: hidden;
        padding-right: 6px;
        box-sizing: border-box;
      }
    }
      .color-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
  }
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
