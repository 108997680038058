<template>
  <div class="role-dialog">
    <el-dialog  :fullscreen="dialogFull"  :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加角色
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
      label-width="80px"
      :model="form"
      label-position="top"
      class="form-class"
      :rules="rules"
      ref="dialog">

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="角色编码" prop='code'>
              <el-input class="form-input" v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色名称" prop='name'>
              <el-input class="form-input" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="排序" prop='sort'>
              <el-input class="form-input" v-model="form.sort"></el-input>
            </el-form-item>
          </el-col>
          <!-- 暂时不用状态 后端容易出问题 先写死 -->
          <!-- <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.status">
                <el-radio-button label="1">正常</el-radio-button>
                <el-radio-button label="0">停用</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { RadioButton, RadioGroup } from 'element-ui';
import { addRole, editRole } from '@/api/saasManage'
import { deepClone } from '@topology/core';
export default {
  name:'AddDialog',
  components: {
    // 'el-radio-group': RadioGroup,
    // 'el-radio-button': RadioButton,
  },
  props: {
    dialog:{
      type:Boolean,
      default:false,
    },
    formData:{
      type:Object,
      default:()=>{},
    }
  },
  data() {
    return {
      dialogs:false,
      dialogFull:false,
      form:{
        status:1, // 状态
        name:'',//角色名称
        code:'', //角色编码
        sort:'' //排序
      },
      rules:{
        code:[
           { required: true, message: '角色编码不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '角色名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
    formData(newVal){
      if(newVal.id){
        this.form = deepClone(newVal);
      }else{
        this.form = {
          status:1, // 状态
          name:'',//角色名称
          code:'', //角色编码
          sort:'' //排序
        };
      }
    }
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            editRole(this.form,this.form.id).then(res=>{
              if(res.data && res.data.code !== 200){
                return false;
              }
              this.$emit('dialogSave');
              this.form = {
                status:1, // 状态
                name:'',//角色名称
                code:'', //角色编码
                sort:'' //排序
              };
            }).catch(() => {
              this.$message.error(`操作错误`);
            });
          }else{
            addRole(this.form).then(res=>{
              if(res.data && res.data.code !== 200){
                return false;
              }
              this.$emit('dialogSave');
              this.form = {
                status:1, // 状态
                name:'',//角色名称
                code:'', //角色编码
                sort:'' //排序
              };
            }).catch(() => {
            this.$message.error(`操作错误`);
          });
          }
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false;
      this.form = {
          status:0, // 是否内置
          name:'',//角色名称
          remarks:'',// 备注
          code:'', //编码
          sort:'' //排序
      };
      this.$emit('dialogClose')
    },
  },
};
</script>
<style lang="less" scoped>

.role-dialog{
  .form-class{
    height:200px;
    overflow:auto;
    padding-bottom: 10px;
  }
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
