<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-22 14:13:33
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:39:12
 * @FilePath: \dataview-next\src\manage-views\auth\roleAuth\components\RoleManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="role-main">
    <div class='main-title'>
      <div class="main-label" >角色管理</div>
      <div class="search">
        <el-input placeholder="请输入名称" v-model="searchName" class="search-input" @input="onRefresh"></el-input>
        <!-- <el-button size='mini' type="primary" icon="el-icon-search" @click="getData">查询</el-button> -->
        <el-button  type="primary" icon="el-icon-plus" @click="onAdd" v-if="judgingAPermission(['role.store'])">新增</el-button>
        <!-- <el-button size='mini' icon='el-icon-refresh' @click="onRefresh">重置</el-button> -->
        <el-popconfirm
          v-if="judgingAPermission(['role.delete']) && deleteData && deleteData.length"
          class="delete-btn"
          title="确定删除这个角色吗？"
          @confirm="onSelectDelete('parent')"
        >
          <el-button slot="reference" type="danger" icon="el-icon-delete-solid">删除</el-button>
        </el-popconfirm>
        
      </div>
    </div>
    <div class="role-table">
      <el-table
      :data="roleData"
      ref="table"
      highlight-current-row 
      :header-cell-style="{background:'#fafafa'}"
      v-loading='loading' 
      @selection-change="onSelectChange"
      @row-click='roleClick'
      >
        <el-table-column :selectable="checkSelectable" type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="角色名称">
          <template #default="{ row }">
            <el-tag>{{row.name}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="角色编码">
          <template #default="{ row }">
            <el-tag>{{row.code}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序号"></el-table-column>
        <el-table-column label="状态" >
          <template>
            <!-- 暂时不用状态 后端容易出问题 先写死 -->
            <!-- <div>
              <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
              </span>
              <span>{{row.status?'正常':'停用'}}
              </span>
            </div> -->
            <div>
              <span class="table-state">
              </span>
              <span>正常
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width='100px'>
          <template #default="{ row }">
            <el-button size="mini"  type="text" @click="onEdit(row)" v-if="judgingAPermission(['role.update'])">修改</el-button>
            <el-popconfirm
              v-if="judgingAPermission(['role.delete']) && !row.is_super"
              class="delete-btn"
              title="确定删除这个角色吗？"
              @confirm='onDelete(row)'
            >
              <el-button v-if="row.parent_id !== 0" size="mini" type="text" slot="reference" >删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 新增编辑弹窗 -->
    <AddDialog 
      :dialog='dialog'
      :formData ='formData'
      @dialogClose='dialogClose' 
      @dialogSave='dialogSave' >
    </AddDialog>
  </div>
</template>
<script>
import { getRole, detRole } from '@/api/saasManage'
import { Popconfirm, Tag } from 'element-ui'
import { judgingAPermission } from '@/utils/tools'
import AddDialog from './AddDialog.vue'
export default {
  name:"RoleManage",
  components: {
    'el-tag': Tag,
    'el-popconfirm': Popconfirm,
    AddDialog,
  },
  props: {},
  data() {
    return {
      judgingAPermission: judgingAPermission,
      formData:{},
      dialog:false,
      searchName: "", // 筛选的name
      loading: false,
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      deleteData:[],
      roleData:[],
    };
  },
  mounted() { 
    this.getData();
  },
  methods: {
    /**
     * @desc:重置筛选
     */
    onRefresh(){
      this.searchName = '';
      this.getData();
    },
    /**
     * 设置禁止选择的数据
     */
    checkSelectable (row) {
      let flag = true
      for (let i = 0; i < this.roleData.length; i++) {
        if (row.is_super) { 
          flag = false
        } else {
          flag = true
        }
      }
      return flag
    },
    /**
     * @desc：table点击行事件
     */
    roleClick(row){
      this.$refs.table.setCurrentRow(row);
      this.$emit('row-click',row);
    },
    /**
     * @desc:弹窗保存
     */
    dialogSave(){
      this.dialog = !this.dialog;
      this.getData();
      this.formData = {};
    },
    dialogClose(){
      this.formData = {};
      this.dialog = false;
      this.getData()
    },
    /**
     * @desc:新增角色
     */
    onAdd(){
      this.dialog = !this.dialog;
    },
      /**
     * @desc:打开编辑弹窗
     */
    onEdit(row){
      this.formData = row;
      this.dialog = !this.dialog;
    },
        /**
     * @desc:普通删除
     */
    onDelete(row){
      detRole({id:[row.id]}).then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete(){
      detRole({id:this.deleteData}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.searchName
      };
      getRole(params).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.roleData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
        /**
     * @desc:table选择时
     */
    onSelectChange(val){
      this.deleteData = [];
      val.forEach(data=>{
        this.deleteData.push(data.id);
      })
    },
  },

};
</script>
<style lang="less">
@import "../../../../css/manageMain.less";
.role-main {
  width: 60%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  .search{
    display:flex;
    .search-input{
      width:150px;
      margin-right:20px
    }
  }
  .role-table{
    font-size: 14px;
    padding-left: 11px;
    padding-right: 16px;
    display: flex;
    flex: 1;
    overflow: auto;
  }
}
</style>